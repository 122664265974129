<template>
<div>
  <b-overlay
        :show="showLoading"
        no-wrap
    />
    <div>
          <h1 class="text-primary font-weight-bolder text-uppercase"
          >Sessions ({{ sessions.length }})</h1>
    <b-card title="Filtres">
        <div class="custom-search">

          <!-- advance search input -->
          <b-row class="d-flex align-items-center">
            <b-col md="3">
              <b-form-group label="Date de début de connexion comprise entre le">
                <!-- <label>Catégorie:</label> -->
                <flat-pickr
                    v-model="filter.start"
                    class="form-control"
                    :config="{  dateFormat: 'd/m/Y'}"
                    @on-change="advanceSearch"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="et le">
                <!-- <label>Catégorie:</label> -->
                <flat-pickr
                    v-model="filter.end"
                    class="form-control"
                    :config="{  dateFormat: 'd/m/Y' , minDate: filter.start}"
                    @on-change="advanceSearch"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Statut">
                <!-- <label>Commercial:</label> -->
                <b-form-select v-model="filter.statut" @change="advanceSearch">
                  <b-form-select-option :value="null"> -- Choisissez --</b-form-select-option>
                  <b-form-select-option value="en cours">En cours
                  </b-form-select-option>
                  <b-form-select-option value="terminée">Terminée
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Recherche">
                <b-input-group>
                  <b-form-input
                      v-model="filter.recherche"
                      :lazy="true"
                      id="basic-recherche"
                      class="d-inline-block"
                      type="text"
                      placeholder="Recherche"
                      @keyup.enter="searchInput"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                        icon="SearchIcon"
                        class="cursor-pointer"
                        @click="searchInput"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-button
                  v-b-tooltip.hover.top="'Tout afficher'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="btn-icon mt-1"
                  type="button"
                  variant="primary"
                  id="revenir-liste-client"
                  @click="resetFilter"
              >
                <feather-icon
                    icon="RefreshCcwIcon"
                />
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card>
        <b-row>
          <b-col md="4">
            <b-card title="Utilisateurs">
            <vue-apex-charts
              type="donut"
              height="350"
              :options="option_type_user.donutChart.chartOptions"
              :series="option_type_user.donutChart.series"
            />
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card title="Première connexion">
            <vue-apex-charts
              type="donut"
              height="350"
              :options="option_type_user_first.donutChart.chartOptions"
              :series="option_type_user_first.donutChart.series"
            />
            </b-card>
          </b-col>
          <b-col md="4">
            <b-row class="match-height">
              <b-col md="6">
                <b-card class="text-center">
                <b-avatar
                    class="mb-1"
                    variant="light-danger"
                    size="35"
                >
                  <feather-icon
                      size="21"
                      icon="UsersIcon"
                  />
                </b-avatar>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ userCount }}
                  </h2>
                  <span>Utilisateurs</span>
                </div>
                </b-card>
              </b-col>
              <b-col md="6">
                <b-card class="text-center">
                <b-avatar
                    class="mb-1"
                    variant="light-danger"
                    size="35"
                >
                  <feather-icon
                      size="21"
                      icon="HomeIcon"
                  />
                </b-avatar>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ cabinetCount }}
                  </h2>
                  <span>Cabinets</span>
                </div>
                </b-card>
              </b-col>
              <b-col md="6">
                <b-card class="text-center">
                <b-avatar
                    class="mb-1"
                    variant="light-danger"
                    size="35"
                >
                  <feather-icon
                      size="21"
                      icon="BarChart2Icon"
                  />
                </b-avatar>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ avgDuree }} min
                  </h2>
                  <span>Durée moyenne</span>
                </div>
                </b-card>
              </b-col>
              <b-col md="6">
                <b-card class="text-center">
                <b-avatar
                    class="mb-1"
                    variant="light-danger"
                    size="35"
                >
                  <feather-icon
                      size="21"
                      icon="ChevronsUpIcon"
                  />
                </b-avatar>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ maxDuree }} min
                  </h2>
                  <span>Durée maximum</span>
                </div>
                
                </b-card>
              </b-col>
              <b-col offset-md="3" md="6">
                <b-card class="text-center">
                <b-avatar
                    class="mb-1"
                    variant="light-danger"
                    size="35"
                >
                  <feather-icon
                      size="21"
                      icon="GridIcon"
                  />
                </b-avatar>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ avgPageVisited }} page(s)
                  </h2>
                  <span>nombre de pages visitées en moyenne</span>
                </div>
                
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      <b-card>
        <div class="mb-1">
          <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="exporterStats"
                    >
                      <span>Exporter </span>
                      <feather-icon
                          class="mr-50"
                          icon="DownloadIcon"
                      />
                    </b-button>
        </div>
        <!-- table -->
        <vue-good-table
            ref="sessions"
            :columns="headers"
            :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
            :rows="sessions"
            :rtl="direction"
            :search-options="{ 
                externalQuery: searchTerm 
            }"
            theme="my-theme"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'statut_field'">
                <template v-if="props.row.statut == 'terminée'">
                    <span :id="`page-row-${props.row.id}`" style="cursor:pointer;" class="mr-50 bullet bullet-danger bullet-sm" />
                    <b-tooltip
                      triggers="hover"
                      :target="`page-row-${props.row.id}`"
                      placement="top"
                      class="tooltip tooltip-scroll"
                    >
                      <p v-for="(page,i) in props.row.pageVisited" :key="'pages-'+page.id" class="mb-0">
                        {{ page.page }}
                      </p>
                    </b-tooltip>
                </template>
                <template v-else>
                    <span :id="`page-row-${props.row.id}`" style="cursor:pointer;" class="mr-50 bullet bullet-success bullet-sm" />
                    <b-tooltip
                      triggers="hover"
                      :target="`page-row-${props.row.id}`"
                      placement="top"
                      class="tooltip tooltip-scroll"
                    >
                      <p v-for="(page,i) in props.row.pageVisited" :key="'pages-'+page.id" class="mb-0">
                        {{ page.page }}
                      </p>
                    </b-tooltip>
                </template>
                <template v-if="props.row.first_connect == 1">
                  <b-badge pill variant="light-success" style="font-size:10px;">Nouveau</b-badge>
                </template>
            </span>
            <!-- <span v-if="props.column.field == 'page_visited'">
              <span :id="`page-row-${props.row.id}`" style="cursor:pointer;">{{ props.row.sessionTrackingPage.length }}</span>
              <b-tooltip
                triggers="hover"
                :target="`page-row-${props.row.id}`"
                placement="top"
              >
                <p v-for="(page,i) in props.row.sessionTrackingPage" :key="'pages-'+page.id" class="mb-0" v-if="i < 10">
                  {{ page.page }}
                </p>
              </b-tooltip>
            </span> -->
            <span v-else>
                {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template
              slot="pagination-bottom"
              slot-scope="props"
          >
            <b-row class="mt-2 align-items-center">
              <b-col md="6" lg="5" xxl="3">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Affichage 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10','25']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> de {{ props.total }} entrées </span>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2 align-items-center">
              <b-col md="4">
                <!-- <b-input-group>
                  <b-form-select
                      v-model="selected_action"
                      :options="options"
                      @change="(selectedrows_masse.selectedRows != null &&
                                selectedrows_masse.selectedRows.length > 0 &&
                                selected_action == 'Attacher') ? $bvModal.show('modal_1') : null"
                  />
                  <b-input-group-append>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="action_masse(selected_action)"
                    >
                      <span>Valider </span>
                      <feather-icon
                          class="mr-50"
                          icon="ArrowRightIcon"
                      />
                    </b-button>
                  </b-input-group-append>
                </b-input-group> -->
              </b-col>
              <b-col md="8">
                <b-pagination
                    :per-page="pageLength"
                    :total-rows="props.total"
                    :value="1"
                    align="right"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                    @input="value => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </template>
          <div slot="emptystate" class="text-center align-middle">
            <span>Aucune donn&eacute;e disponible dans le tableau</span>
          </div>
        </vue-good-table>

        <!-- <template #code>
          {{ codeAdvance }}
        </template> -->
      </b-card>
    </div>
</div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BPagination,
  BRow,
  VBToggle,
  VBTooltip,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BInputGroupPrepend,
  BBadge,
  BTooltip
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import flatPickr from 'vue-flatpickr-component'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
export default {
  methods: {
    getAllSessions(){
      this.$http.get('/statistiques/allsessions')
        .then(r => {
          this.maxDuree = 0;
          this.avgDuree = 0;
          this.avgPageVisited = 0;
          this.sessions = r.data.data.all
          this.sessionsKeep = r.data.data.all
          this.option_type_user.donutChart.series = [0,0];
          this.option_type_user_first.donutChart.series = [0,0];
          this.cabinetCount = r.data.data.byCabinet.length
          this.userCount = r.data.data.byUser.length
          r.data.data.all.forEach(element => {
            if(this.maxDuree < element.duree)
            {
              this.maxDuree = element.duree;
            }
            this.avgDuree += element.duree;
            this.avgPageVisited += parseInt(element.sessionTrackingPage)
          });
          if(this.maxDuree > 0){
            this.maxDuree = parseInt(this.maxDuree)
          }
          if(this.avgDuree > 0){
            this.avgDuree = parseInt(this.avgDuree / r.data.data.all.length);
          }
          if(this.avgPageVisited > 0){
            this.avgPageVisited = parseInt(this.avgPageVisited / r.data.data.all.length);
          }
          r.data.data.byUser.forEach(element => {
            if(element.type_user == 'Admin'){
              this.option_type_user.donutChart.series[0] = this.option_type_user.donutChart.series[0] + 1;
            }
            else
            {
              this.option_type_user.donutChart.series[1] = this.option_type_user.donutChart.series[1] + 1;
            }
          });
          r.data.data.ByUserFirstConnect.forEach(element => {
            if(element.type_user == 'Admin'){
              this.option_type_user_first.donutChart.series[0] = this.option_type_user_first.donutChart.series[0] + 1;
            }
            else
            {
              this.option_type_user_first.donutChart.series[1] = this.option_type_user_first.donutChart.series[1] + 1;
            }
          });
          this.showLoading = false
        })
        .catch(err=>{
          this.showLoading = false
          console.log(err)
        })
    },
    fetchSessions(){
      this.$http.get('/statistiques/sessions')
        .then(r => {
          this.maxDuree = 0;
          this.avgDuree = 0;
          this.avgPageVisited = 0;
          this.sessions = r.data.data.all
          this.sessionsKeep = r.data.data.all
          this.option_type_user.donutChart.series = [0,0];
          this.option_type_user_first.donutChart.series = [0,0];
          this.cabinetCount = r.data.data.byCabinet.length
          this.userCount = r.data.data.byUser.length
          r.data.data.all.forEach(element => {
            if(this.maxDuree < element.duree)
            {
              this.maxDuree = element.duree;
            }
            this.avgDuree += element.duree;
            this.avgPageVisited += parseInt(element.sessionTrackingPage)
          });
          if(this.maxDuree > 0){
            this.maxDuree = parseInt(this.maxDuree)
          }
          if(this.avgDuree > 0){
            this.avgDuree = parseInt(this.avgDuree / r.data.data.all.length);
          }
          if(this.avgPageVisited > 0){
            this.avgPageVisited = parseInt(this.avgPageVisited / r.data.data.all.length);
          }
          r.data.data.byUser.forEach(element => {
            if(element.type_user == 'Admin'){
              this.option_type_user.donutChart.series[0] = this.option_type_user.donutChart.series[0] + 1;
            }
            else
            {
              this.option_type_user.donutChart.series[1] = this.option_type_user.donutChart.series[1] + 1;
            }
          });
          r.data.data.ByUserFirstConnect.forEach(element => {
            if(element.type_user == 'Admin'){
              this.option_type_user_first.donutChart.series[0] = this.option_type_user_first.donutChart.series[0] + 1;
            }
            else
            {
              this.option_type_user_first.donutChart.series[1] = this.option_type_user_first.donutChart.series[1] + 1;
            }
          });
          this.showLoading = false
        })
        .catch(err=>{
          this.showLoading = false
          console.log(err)
        })
    },
    dateToSqlDate(date){
      if(date){
        let splited_date = date.split('/');
        date = splited_date[2] + '-' + splited_date[1] + '-'+ splited_date[0]
      }
      return date;
    },
    advanceSearch() {
    this.showLoading = true
    if(this.filter.start == null && this.filter.end == null && this.filter.statut == null)
    {
        // this.fetchSessions();
        this.getAllSessions();
    }
    else
    {
      this.$http.post('/statistiques/sessions',{start: this.dateToSqlDate(this.filter.start),end: this.dateToSqlDate(this.filter.end),statut: this.filter.statut})
        .then(r => {
          this.maxDuree = 0;
          this.avgDuree = 0;
          this.avgPageVisited = 0;
          this.option_type_user.donutChart.series = [0,0];
          this.option_type_user_first.donutChart.series = [0,0];
          this.sessions = r.data.data.all
          this.cabinetCount = r.data.data.byCabinet.length
          this.userCount = r.data.data.byUser.length
          r.data.data.all.forEach(element => {
            if(this.maxDuree < element.duree)
            {
              this.maxDuree = element.duree;
            }
            this.avgDuree += element.duree;
            this.avgPageVisited += parseInt(element.sessionTrackingPage)
          });
          if(this.maxDuree > 0){
            this.maxDuree = parseInt(this.maxDuree)
          }
          if(this.avgDuree > 0){
            this.avgDuree = parseInt(this.avgDuree / r.data.data.all.length);
          }
          if(this.avgPageVisited > 0){
            this.avgPageVisited = parseInt(this.avgPageVisited / r.data.data.all.length);
          }
          r.data.data.byUser.forEach(element => {
            if(element.type_user == 'Admin'){
              this.option_type_user.donutChart.series[0] = this.option_type_user.donutChart.series[0] + 1;
            }
            else
            {
              this.option_type_user.donutChart.series[1] = this.option_type_user.donutChart.series[1] + 1;
            }
          });
          r.data.data.ByUserFirstConnect.forEach(element => {
            if(element.type_user == 'Admin'){
              this.option_type_user_first.donutChart.series[0] = this.option_type_user_first.donutChart.series[0] + 1;
            }
            else
            {
              this.option_type_user_first.donutChart.series[1] = this.option_type_user_first.donutChart.series[1] + 1;
            }
          });
          this.sessionsKeep = r.data.data.all
          this.showLoading = false
        })
        .catch(err=>{
          this.showLoading = false
          console.log(err)
        })
    }
    },
    exporterStats(){
      this.showLoading = true
        this.$http.post('/statistiques/sessions/export',{start: this.dateToSqlDate(this.filter.start),end: this.dateToSqlDate(this.filter.end),statut: this.filter.statut},{
          responseType: "blob"  
        })
          .then(r => {
            this.downloadDocument(r.data, 'SESSIONS_STATISTICS')
            this.showLoading = false
          })
          .catch(err=>{
            this.showLoading = false
            this.messageToast('une erreur a été survenue', 'Erreur', 'warning')
            console.log(err)
          })
    },
    downloadDocument(blob, name) {
      if (blob) {
        var fileURL = window.URL.createObjectURL(new Blob([blob]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${name}_${moment().format("YYYYMMDDHHmm")}.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
      } else {
        this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
      }
    },
    resetFilter() {
      this.filter.start = null
      this.filter.end = null
      this.filter.statut = null
      this.filter.recherche = null
      this.searchTerm = ''
      this.advanceSearch();
    //   this.advanceSearch(null)
        // this.fetchSessions();
    },
    // filterStatut(){
    //     if(this.filter.statut != null){
    //         console.log(this.filter.statut)
    //         this.sessions.filter(ss => ss.statut == this.filter.statut)
    //     }
    //     else
    //     {
    //         this.sessions = this.sessionsKeep
    //     }
    // },
    searchInput(){
      this.showLoading = true
      setTimeout(() => {
        if(this.filter.recherche != null) {
          this.searchTerm = this.filter.recherche
          this.showLoading = false
        }
        this.showLoading = false
      }, 500);
    },
  },
  created () {
    this.showLoading = true
    //this.fetchSessions();
  },
  data () {
    return {
        cabinetCount: 0,
        userCount: 0,
        maxDuree: 0,
        avgDuree: 0,
        avgPageVisited: 0,
        option_type_user: {
          donutChart: {
            series: [1, 1],
            chartOptions: {
              legend: {
                show: true,
                position: 'bottom',
                fontSize: '14px',
                fontFamily: 'Montserrat',
              },
              colors: [
                '#7954e1',
                '#00d4bd',
              ],
              dataLabels: {
                enabled: true,
                formatter(val) {
                  //console.log(val)
                  // eslint-disable-next-line radix
                  return `${parseInt(val)}%`
                },
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        fontSize: '2rem',
                        fontFamily: 'Montserrat',
                      },
                      value: {
                        fontSize: '1rem',
                        fontFamily: 'Montserrat',
                        formatter(val) {
                          //console.log(val)
                          // eslint-disable-next-line radix
                          return `${parseInt(val)}`
                        },
                      },
                      total: {
                        show: true,
                        fontSize: '1rem',
                        label: 'type utilisateur',
                        formatter() {
                          return ''
                        },
                      },
                    },
                  },
                },
              },
              labels: ['Admin', 'utilisateur'],
              responsive: [
                {
                  breakpoint: 992,
                  options: {
                    chart: {
                      height: 380,
                    },
                    legend: {
                      position: 'bottom',
                    },
                  },
                },
                {
                  breakpoint: 576,
                  options: {
                    chart: {
                      height: 320,
                    },
                    plotOptions: {
                      pie: {
                        donut: {
                          labels: {
                            show: true,
                            name: {
                              fontSize: '1.5rem',
                            },
                            value: {
                              fontSize: '1rem',
                            },
                            total: {
                              fontSize: '1.5rem',
                            },
                          },
                        },
                      },
                    },
                    legend: {
                      show: true,
                    },
                  },
                },
              ],
            },
          }
        },
        option_type_user_first: {
          donutChart: {
            series: [1, 1],
            chartOptions: {
              legend: {
                show: true,
                position: 'bottom',
                fontSize: '14px',
                fontFamily: 'Montserrat',
              },
              colors: [
                '#7954e1',
                '#00d4bd',
              ],
              dataLabels: {
                enabled: true,
                formatter(val) {
                  //console.log(val)
                  // eslint-disable-next-line radix
                  return `${parseInt(val)}%`
                },
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        fontSize: '2rem',
                        fontFamily: 'Montserrat',
                      },
                      value: {
                        fontSize: '1rem',
                        fontFamily: 'Montserrat',
                        formatter(val) {
                          //console.log(val)
                          // eslint-disable-next-line radix
                          return `${parseInt(val)}`
                        },
                      },
                      total: {
                        show: true,
                        fontSize: '1rem',
                        label: 'type utilisateur',
                        formatter() {
                          return ''
                        },
                      },
                    },
                  },
                },
              },
              labels: ['Admin', 'Utilisateur'],
              responsive: [
                {
                  breakpoint: 992,
                  options: {
                    chart: {
                      height: 380,
                    },
                    legend: {
                      position: 'bottom',
                    },
                  },
                },
                {
                  breakpoint: 576,
                  options: {
                    chart: {
                      height: 320,
                    },
                    plotOptions: {
                      pie: {
                        donut: {
                          labels: {
                            show: true,
                            name: {
                              fontSize: '1.5rem',
                            },
                            value: {
                              fontSize: '1rem',
                            },
                            total: {
                              fontSize: '1.5rem',
                            },
                          },
                        },
                      },
                    },
                    legend: {
                      show: true,
                    },
                  },
                },
              ],
            },
          }
        },
        showLoading: false,
        pageLength: 10,
        searchTerm: '',
        filter: {
            start: null,
            end: null,
            statut: null,
            recherche: null
        },
        sessions: [],
        sessionsKeep: [],
        headers: [
        {
          label: 'Cabinet',
          field: 'cabinet',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Utilisateur',
          field: 'utilisateur',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Date de début',
          field: 'start',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Date de fin',
          field: 'end',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Adresse ip',
          field: 'adresse_ip',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Type utilisateur',
          field: 'type_user',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Pages',
          field: 'sessionTrackingPage',
          type: 'number',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Statut',
          field: 'statut',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6 text-capitalize'
        },
        {
          label: 'Details',
          field: 'statut_field',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
      ],
    }
  },
    components: {
    VueApexCharts,
    Ripple,
    VBTooltip,
    VueGoodTable,
    BAvatar,
    BInputGroupPrepend,
    BPagination,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormSelectOption,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
    flatPickr,
    BBadge,
    BTooltip
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.filter.start = new Date();
    this.filter.end = new Date();
    this.fetchSessions();
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
<style>

</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>